import { Injectable } from '@angular/core';

const ENABLE_LOGGING: boolean = true;

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() { }

  public logDebug(message?: any, ...optionalParams: any[]): void {
    if (!ENABLE_LOGGING) {
      return;
    }
    console.log('[DEBUG] ' + this.getTime());
    this.logMessageWithParameters(message, ...optionalParams);
  }

  public logInfo(message?: any, ...optionalParams: any[]): void {
    if (!ENABLE_LOGGING) {
      return;
    }
    console.log('[INFO] ' + this.getTime());
    this.logMessageWithParameters(message, ...optionalParams);
  }

  public logWarning(message?: any, ...optionalParams: any[]): void {
    if (!ENABLE_LOGGING) {
      return;
    }
    console.log('[WARNING] ' + this.getTime());
    this.logMessageWithParameters(message, ...optionalParams);
  }

  public logError(message?: any, ...optionalParams: any[]): void {
    if (!ENABLE_LOGGING) {
      return;
    }
    console.error('[ERROR] ' + this.getTime());
    this.logMessageWithParameters(message, ...optionalParams);
  }

  public logErrorWithCause(cause: any, message?: any, ...optionalParams: any[]): void {
    if (!ENABLE_LOGGING) {
      return;
    }
    console.error('[ERROR] ' + this.getTime());
    this.logMessageWithParameters(message, ...optionalParams);
    console.error(cause);
  }

  private logMessageWithParameters(message?: any, ...optionalParams: any[]) {
    if (optionalParams && optionalParams.length > 0) {
      console.log(message, ...optionalParams);
    } else {
      console.log(message);
    }
  }

  private getTime(): string {
    return new Date().toLocaleString();
  }
}
