import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../components/header/header.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { DeviceService } from '../../../general/services/device.service';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { LocalizationService } from '../../../general/services/localization.service';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ProgressDialogService } from '../../../general/services/progress-dialog.service';
import { BackendService } from '../../services/backend.service';
import { ToastService } from '../../../general/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { FormService } from '../../../general/services/form.service';
import { MatTableModule } from '@angular/material/table';
import { NavigationService } from '../../services/navigation.service';
import { StripePricingComponent } from '../../components/stripe-pricing/stripe-pricing.component';
import { WaiterNowPricingComponent } from '../../components/waiter-now-pricing/waiter-now-pricing.component';
import { Country } from '../../../general/services/localization.service';
import { ActivatedRoute } from '@angular/router';
import { FloatingButtonComponent } from 'src/app/general/components/floating-button/floating-button.component';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { TableLayoutComponent } from 'src/app/general/components/table-layout/table-layout.component';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    MatDividerModule,
    MatTableModule,
    StripePricingComponent,
    WaiterNowPricingComponent,
    FloatingButtonComponent,
    TitleComponent,
    TableLayoutComponent
  ],
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css', '../../../../styles.css']
})
export class HomePageComponent implements OnInit {
  readonly US: Country = Country.Us;
  readonly MEXICO: Country = Country.Mexico;

  isGoToTopButtonVisible: boolean;

  nameFormControl: FormControl;
  emailFormControl: FormControl;
  phoneFormControl: FormControl;
  businessNameFormControl: FormControl;
  businessAddressFormControl: FormControl;

  textRequiredFieldErrorMessage: string;
  textInvalidValueErrorMessage: string;
  textSendingRegistration: string;
  textRegistrationSubmitted: string;
  textErrorSubmittingRegistration: string;

  constructor(
      public deviceService: DeviceService,
      public localizationService: LocalizationService,
      public navigationService: NavigationService,
      private progressDialogService: ProgressDialogService,
      private backendService: BackendService,
      private toastService: ToastService,
      private translateService: TranslateService,
      public formService: FormService,
      private activatedRoute: ActivatedRoute) {
    this.isGoToTopButtonVisible = true;
    this.nameFormControl = new FormControl('', [Validators.required]);
    this.emailFormControl = new FormControl('', [Validators.required, Validators.email]);
    this.phoneFormControl = new FormControl('', [Validators.required]);
    this.businessNameFormControl = new FormControl('', [Validators.required]);
    this.businessAddressFormControl = new FormControl('', [Validators.required]);

    // Localized texts are initialized here because it is very likely that the observable
    // returned by the translate service will be completed before these texts are actually used.
    // This reduces code boilerplate.
    this.textRequiredFieldErrorMessage = '';
    this.translateService.get('error_required_value').subscribe(localizedText => {
      this.textRequiredFieldErrorMessage = localizedText;
    });
    this.textInvalidValueErrorMessage = '';
    this.translateService.get('error_invalid_value').subscribe(localizedText => {
      this.textInvalidValueErrorMessage = localizedText;
    });
    this.textSendingRegistration = '';
    this.translateService.get('sending_registration').subscribe(localizedText => {
      this.textSendingRegistration = localizedText;
    });
    this.textRegistrationSubmitted = '';
    this.translateService.get('registration_submitted').subscribe(localizedText => {
      this.textRegistrationSubmitted = localizedText;
    });
    this.textErrorSubmittingRegistration = '';
    this.translateService.get('error_submitting_registration').subscribe(localizedText => {
      this.textErrorSubmittingRegistration = localizedText;
    });
  }

  ngOnInit(): void {
    this.navigationService.handleRedirect(this.activatedRoute, /* onNotRedirected= */ () => {
      // Put any remaining code on ngOnInit() inside this scope. Nothing should be after
      // this.navigationService.handleRedirect() otherwise it will execute regardless
      // the page is redirected.
      // navigationService.handleRedirect() uses async code to get query parameters and
      // thus it cannot return whether the page was redirected.
    });
  }

  public getCoverImageClassName(): string {
    if (this.deviceService.isMobile()) {
      if (this.localizationService.isSpanish()) {
        return 'cover-image-mobile-es';
      } else {
        return 'cover-image-mobile-en';
      }
    } else {
      if (this.localizationService.isSpanish()) {
        return 'cover-image-es';
      } else {
        return 'cover-image-en';
      }
    }
  }

  public goToFoodTruckPage(): void {
    this.navigationService.goToFoodTruckPage();
  }

  public goToRestaurantPage(): void {
    this.navigationService.goToRestaurantPage();
  }

  public goToFacebook(): void {
    this.navigationService.goToFacebook();
  }

  public goToInstagram(): void {
    this.navigationService.goToInstagram();
  }

  public goToYouTube(): void {
    this.navigationService.goToYouTube();
  }

  public goToTwitter(): void {
    this.navigationService.goToTwitter();
  }

  public goToLinkedin(): void {
    this.navigationService.goToLinkedin();
  }

  public isSpanish(): boolean {
    return this.localizationService.isSpanish();
  }

  public scrollToElement(element: HTMLElement): void {
    element.scrollIntoView({behavior: 'smooth'});
  }

  /*
  This is not working
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event: any): void {
    console.log(window.screenY);
    console.log(window.innerHeight);
    this.isGoToTopButtonVisible = window.screenY > window.innerHeight;
  }
  */

  public submitBusinessRegistration(): void {
    const subject = 'Business registration for ' + this.businessNameFormControl.value;
    let body = "<p>Name: " + this.nameFormControl.value;
    body += "<p>Email: " + this.emailFormControl.value;
    body += "<p>Phone number: " + this.phoneFormControl.value;
    body += "<p>Business name: " + this.businessNameFormControl.value;
    body += "<p>Business address: " + this.businessAddressFormControl.value;

    const closeDialog = this.progressDialogService.open(this.textSendingRegistration);
    /*
      of([1,2,3]).subscribe({
        next: (v) => console.log(v),
        error: (e) => console.error(e),
        complete: () => console.info('complete')
      })
    */
    this.backendService.sendEmailToContact(
      this.emailFormControl.value,
      subject,
      body,
      /* onSuccess= */ () => {
        closeDialog();
        this.toastService.success(this.textRegistrationSubmitted);
      },
      /* onError= */ error => {
        closeDialog();
        this.toastService.success(this.textErrorSubmittingRegistration);
      });
  }
}
