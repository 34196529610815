/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.waiternow = (function() {

    /**
     * Namespace waiternow.
     * @exports waiternow
     * @namespace
     */
    var waiternow = {};

    waiternow.common = (function() {

        /**
         * Namespace common.
         * @memberof waiternow
         * @namespace
         */
        var common = {};

        /**
         * EmailRecipient enum.
         * @name waiternow.common.EmailRecipient
         * @enum {number}
         * @property {number} EMAIL_RECIPIENT_UNSPECIFIED=0 EMAIL_RECIPIENT_UNSPECIFIED value
         * @property {number} INFO=1 INFO value
         * @property {number} SYSTEM=2 SYSTEM value
         * @property {number} SUPPORT=3 SUPPORT value
         * @property {number} CONTACT=4 CONTACT value
         * @property {number} FEEDBACK=5 FEEDBACK value
         * @property {number} SALES=6 SALES value
         * @property {number} BILLING=7 BILLING value
         * @property {number} QR_CODE_SALES=8 QR_CODE_SALES value
         */
        common.EmailRecipient = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "EMAIL_RECIPIENT_UNSPECIFIED"] = 0;
            values[valuesById[1] = "INFO"] = 1;
            values[valuesById[2] = "SYSTEM"] = 2;
            values[valuesById[3] = "SUPPORT"] = 3;
            values[valuesById[4] = "CONTACT"] = 4;
            values[valuesById[5] = "FEEDBACK"] = 5;
            values[valuesById[6] = "SALES"] = 6;
            values[valuesById[7] = "BILLING"] = 7;
            values[valuesById[8] = "QR_CODE_SALES"] = 8;
            return values;
        })();

        common.SendEmailActionProto = (function() {

            /**
             * Properties of a SendEmailActionProto.
             * @memberof waiternow.common
             * @interface ISendEmailActionProto
             */

            /**
             * Constructs a new SendEmailActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a SendEmailActionProto.
             * @implements ISendEmailActionProto
             * @constructor
             * @param {waiternow.common.ISendEmailActionProto=} [properties] Properties to set
             */
            function SendEmailActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SendEmailActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {waiternow.common.ISendEmailActionProto=} [properties] Properties to set
             * @returns {waiternow.common.SendEmailActionProto} SendEmailActionProto instance
             */
            SendEmailActionProto.create = function create(properties) {
                return new SendEmailActionProto(properties);
            };

            /**
             * Encodes the specified SendEmailActionProto message. Does not implicitly {@link waiternow.common.SendEmailActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {waiternow.common.ISendEmailActionProto} message SendEmailActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendEmailActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified SendEmailActionProto message, length delimited. Does not implicitly {@link waiternow.common.SendEmailActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {waiternow.common.ISendEmailActionProto} message SendEmailActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendEmailActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SendEmailActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SendEmailActionProto} SendEmailActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendEmailActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendEmailActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SendEmailActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SendEmailActionProto} SendEmailActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendEmailActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SendEmailActionProto message.
             * @function verify
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SendEmailActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SendEmailActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SendEmailActionProto} SendEmailActionProto
             */
            SendEmailActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SendEmailActionProto)
                    return object;
                return new $root.waiternow.common.SendEmailActionProto();
            };

            /**
             * Creates a plain object from a SendEmailActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {waiternow.common.SendEmailActionProto} message SendEmailActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SendEmailActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SendEmailActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SendEmailActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SendEmailActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SendEmailActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SendEmailActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SendEmailActionProto";
            };

            SendEmailActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.SendEmailActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IEmailAddressProto|null} [from] Request from
                 * @property {waiternow.common.EmailRecipient|null} [to] Request to
                 * @property {string|null} [subject] Request subject
                 * @property {string|null} [body] Request body
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.SendEmailActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.SendEmailActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request from.
                 * @member {waiternow.common.IEmailAddressProto|null|undefined} from
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 */
                Request.prototype.from = null;

                /**
                 * Request to.
                 * @member {waiternow.common.EmailRecipient} to
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 */
                Request.prototype.to = 0;

                /**
                 * Request subject.
                 * @member {string} subject
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 */
                Request.prototype.subject = "";

                /**
                 * Request body.
                 * @member {string} body
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 */
                Request.prototype.body = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.SendEmailActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.SendEmailActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                        $root.waiternow.common.EmailAddressProto.encode(message.from, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.to);
                    if (message.subject != null && Object.hasOwnProperty.call(message, "subject"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.subject);
                    if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.body);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.SendEmailActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SendEmailActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendEmailActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.from = $root.waiternow.common.EmailAddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.to = reader.int32();
                                break;
                            }
                        case 3: {
                                message.subject = reader.string();
                                break;
                            }
                        case 4: {
                                message.body = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SendEmailActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.from != null && message.hasOwnProperty("from")) {
                        var error = $root.waiternow.common.EmailAddressProto.verify(message.from);
                        if (error)
                            return "from." + error;
                    }
                    if (message.to != null && message.hasOwnProperty("to"))
                        switch (message.to) {
                        default:
                            return "to: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                    if (message.subject != null && message.hasOwnProperty("subject"))
                        if (!$util.isString(message.subject))
                            return "subject: string expected";
                    if (message.body != null && message.hasOwnProperty("body"))
                        if (!$util.isString(message.body))
                            return "body: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SendEmailActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SendEmailActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.SendEmailActionProto.Request();
                    if (object.from != null) {
                        if (typeof object.from !== "object")
                            throw TypeError(".waiternow.common.SendEmailActionProto.Request.from: object expected");
                        message.from = $root.waiternow.common.EmailAddressProto.fromObject(object.from);
                    }
                    switch (object.to) {
                    default:
                        if (typeof object.to === "number") {
                            message.to = object.to;
                            break;
                        }
                        break;
                    case "EMAIL_RECIPIENT_UNSPECIFIED":
                    case 0:
                        message.to = 0;
                        break;
                    case "INFO":
                    case 1:
                        message.to = 1;
                        break;
                    case "SYSTEM":
                    case 2:
                        message.to = 2;
                        break;
                    case "SUPPORT":
                    case 3:
                        message.to = 3;
                        break;
                    case "CONTACT":
                    case 4:
                        message.to = 4;
                        break;
                    case "FEEDBACK":
                    case 5:
                        message.to = 5;
                        break;
                    case "SALES":
                    case 6:
                        message.to = 6;
                        break;
                    case "BILLING":
                    case 7:
                        message.to = 7;
                        break;
                    case "QR_CODE_SALES":
                    case 8:
                        message.to = 8;
                        break;
                    }
                    if (object.subject != null)
                        message.subject = String(object.subject);
                    if (object.body != null)
                        message.body = String(object.body);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.from = null;
                        object.to = options.enums === String ? "EMAIL_RECIPIENT_UNSPECIFIED" : 0;
                        object.subject = "";
                        object.body = "";
                    }
                    if (message.from != null && message.hasOwnProperty("from"))
                        object.from = $root.waiternow.common.EmailAddressProto.toObject(message.from, options);
                    if (message.to != null && message.hasOwnProperty("to"))
                        object.to = options.enums === String ? $root.waiternow.common.EmailRecipient[message.to] === undefined ? message.to : $root.waiternow.common.EmailRecipient[message.to] : message.to;
                    if (message.subject != null && message.hasOwnProperty("subject"))
                        object.subject = message.subject;
                    if (message.body != null && message.hasOwnProperty("body"))
                        object.body = message.body;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SendEmailActionProto.Request";
                };

                return Request;
            })();

            SendEmailActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.SendEmailActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.SendEmailActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.SendEmailActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.SendEmailActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.SendEmailActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.SendEmailActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SendEmailActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendEmailActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SendEmailActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SendEmailActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SendEmailActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.SendEmailActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.SendEmailActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SendEmailActionProto.Response";
                };

                return Response;
            })();

            return SendEmailActionProto;
        })();

        common.EmailAddressProto = (function() {

            /**
             * Properties of an EmailAddressProto.
             * @memberof waiternow.common
             * @interface IEmailAddressProto
             * @property {string|null} [email] EmailAddressProto email
             * @property {string|null} [name] EmailAddressProto name
             */

            /**
             * Constructs a new EmailAddressProto.
             * @memberof waiternow.common
             * @classdesc Represents an EmailAddressProto.
             * @implements IEmailAddressProto
             * @constructor
             * @param {waiternow.common.IEmailAddressProto=} [properties] Properties to set
             */
            function EmailAddressProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EmailAddressProto email.
             * @member {string} email
             * @memberof waiternow.common.EmailAddressProto
             * @instance
             */
            EmailAddressProto.prototype.email = "";

            /**
             * EmailAddressProto name.
             * @member {string} name
             * @memberof waiternow.common.EmailAddressProto
             * @instance
             */
            EmailAddressProto.prototype.name = "";

            /**
             * Creates a new EmailAddressProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {waiternow.common.IEmailAddressProto=} [properties] Properties to set
             * @returns {waiternow.common.EmailAddressProto} EmailAddressProto instance
             */
            EmailAddressProto.create = function create(properties) {
                return new EmailAddressProto(properties);
            };

            /**
             * Encodes the specified EmailAddressProto message. Does not implicitly {@link waiternow.common.EmailAddressProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {waiternow.common.IEmailAddressProto} message EmailAddressProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EmailAddressProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified EmailAddressProto message, length delimited. Does not implicitly {@link waiternow.common.EmailAddressProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {waiternow.common.IEmailAddressProto} message EmailAddressProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EmailAddressProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EmailAddressProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.EmailAddressProto} EmailAddressProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EmailAddressProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EmailAddressProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.email = reader.string();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EmailAddressProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.EmailAddressProto} EmailAddressProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EmailAddressProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EmailAddressProto message.
             * @function verify
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EmailAddressProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates an EmailAddressProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.EmailAddressProto} EmailAddressProto
             */
            EmailAddressProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.EmailAddressProto)
                    return object;
                var message = new $root.waiternow.common.EmailAddressProto();
                if (object.email != null)
                    message.email = String(object.email);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from an EmailAddressProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {waiternow.common.EmailAddressProto} message EmailAddressProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EmailAddressProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.email = "";
                    object.name = "";
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this EmailAddressProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.EmailAddressProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EmailAddressProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EmailAddressProto
             * @function getTypeUrl
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EmailAddressProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.EmailAddressProto";
            };

            return EmailAddressProto;
        })();

        common.OperationStatusProto = (function() {

            /**
             * Properties of an OperationStatusProto.
             * @memberof waiternow.common
             * @interface IOperationStatusProto
             * @property {boolean|null} [isSuccess] OperationStatusProto isSuccess
             * @property {boolean|null} [isFailure] OperationStatusProto isFailure
             * @property {string|null} [errorCode] OperationStatusProto errorCode
             * @property {string|null} [errorMessage] OperationStatusProto errorMessage
             */

            /**
             * Constructs a new OperationStatusProto.
             * @memberof waiternow.common
             * @classdesc Represents an OperationStatusProto.
             * @implements IOperationStatusProto
             * @constructor
             * @param {waiternow.common.IOperationStatusProto=} [properties] Properties to set
             */
            function OperationStatusProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OperationStatusProto isSuccess.
             * @member {boolean} isSuccess
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             */
            OperationStatusProto.prototype.isSuccess = false;

            /**
             * OperationStatusProto isFailure.
             * @member {boolean} isFailure
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             */
            OperationStatusProto.prototype.isFailure = false;

            /**
             * OperationStatusProto errorCode.
             * @member {string} errorCode
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             */
            OperationStatusProto.prototype.errorCode = "";

            /**
             * OperationStatusProto errorMessage.
             * @member {string} errorMessage
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             */
            OperationStatusProto.prototype.errorMessage = "";

            /**
             * Creates a new OperationStatusProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {waiternow.common.IOperationStatusProto=} [properties] Properties to set
             * @returns {waiternow.common.OperationStatusProto} OperationStatusProto instance
             */
            OperationStatusProto.create = function create(properties) {
                return new OperationStatusProto(properties);
            };

            /**
             * Encodes the specified OperationStatusProto message. Does not implicitly {@link waiternow.common.OperationStatusProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {waiternow.common.IOperationStatusProto} message OperationStatusProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OperationStatusProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.isSuccess != null && Object.hasOwnProperty.call(message, "isSuccess"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isSuccess);
                if (message.isFailure != null && Object.hasOwnProperty.call(message, "isFailure"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isFailure);
                if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.errorCode);
                if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.errorMessage);
                return writer;
            };

            /**
             * Encodes the specified OperationStatusProto message, length delimited. Does not implicitly {@link waiternow.common.OperationStatusProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {waiternow.common.IOperationStatusProto} message OperationStatusProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OperationStatusProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OperationStatusProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.OperationStatusProto} OperationStatusProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OperationStatusProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OperationStatusProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.isSuccess = reader.bool();
                            break;
                        }
                    case 2: {
                            message.isFailure = reader.bool();
                            break;
                        }
                    case 3: {
                            message.errorCode = reader.string();
                            break;
                        }
                    case 4: {
                            message.errorMessage = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OperationStatusProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.OperationStatusProto} OperationStatusProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OperationStatusProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OperationStatusProto message.
             * @function verify
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OperationStatusProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.isSuccess != null && message.hasOwnProperty("isSuccess"))
                    if (typeof message.isSuccess !== "boolean")
                        return "isSuccess: boolean expected";
                if (message.isFailure != null && message.hasOwnProperty("isFailure"))
                    if (typeof message.isFailure !== "boolean")
                        return "isFailure: boolean expected";
                if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                    if (!$util.isString(message.errorCode))
                        return "errorCode: string expected";
                if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                    if (!$util.isString(message.errorMessage))
                        return "errorMessage: string expected";
                return null;
            };

            /**
             * Creates an OperationStatusProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.OperationStatusProto} OperationStatusProto
             */
            OperationStatusProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.OperationStatusProto)
                    return object;
                var message = new $root.waiternow.common.OperationStatusProto();
                if (object.isSuccess != null)
                    message.isSuccess = Boolean(object.isSuccess);
                if (object.isFailure != null)
                    message.isFailure = Boolean(object.isFailure);
                if (object.errorCode != null)
                    message.errorCode = String(object.errorCode);
                if (object.errorMessage != null)
                    message.errorMessage = String(object.errorMessage);
                return message;
            };

            /**
             * Creates a plain object from an OperationStatusProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {waiternow.common.OperationStatusProto} message OperationStatusProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OperationStatusProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.isSuccess = false;
                    object.isFailure = false;
                    object.errorCode = "";
                    object.errorMessage = "";
                }
                if (message.isSuccess != null && message.hasOwnProperty("isSuccess"))
                    object.isSuccess = message.isSuccess;
                if (message.isFailure != null && message.hasOwnProperty("isFailure"))
                    object.isFailure = message.isFailure;
                if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                    object.errorCode = message.errorCode;
                if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                    object.errorMessage = message.errorMessage;
                return object;
            };

            /**
             * Converts this OperationStatusProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OperationStatusProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OperationStatusProto
             * @function getTypeUrl
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OperationStatusProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.OperationStatusProto";
            };

            return OperationStatusProto;
        })();

        return common;
    })();

    return waiternow;
})();

module.exports = $root;
