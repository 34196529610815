import { Injectable } from '@angular/core';
import { Observable, catchError, map, retry, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppError } from '../util/error';
import { Function, Runnable, Consumer } from 'src/app/general/interfaces/functions';
import { LoggingService } from 'src/app/general/services/logging.service';
import * as proto from 'src/proto/compiled-protos';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(
      private httpClient: HttpClient,
      private loggingService: LoggingService) {
  }

  public sendEmailToContact(fromEmail: string, subject: string, body:string, onSuccess: Runnable, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.SendEmailActionProto.Request();
    // If 'from' is set we get a warning: This may be a spoofed messaghe. The message claims to have
    //s been sent from your account, but Gmail couldn't verify the actual source.
    // request.from = new protos.waiternow.common.EmailAddressProto();
    // request.from.email = fromEmail;
    request.subject = subject;
    request.body = body;
    request.to = proto.waiternow.common.EmailRecipient.CONTACT;

    this.httpPostWithProtoRequestAndProtoResponse(
      environment.backend.url + '/service/common/email/send',
      request,
      protoRequest => proto.waiternow.common.SendEmailActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.SendEmailActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  private extractDataAndMapOperationStatusErrorToObservableError<R, D>(
      extractOperationStatus: (response: R) => proto.waiternow.common.IOperationStatusProto | null | undefined,
      extractData: (response: R) => D): (response: R) => D {
    return response => {
      const operationstatus = extractOperationStatus(response);
      if (operationstatus && operationstatus.isFailure) {
        const errorMessage = 'Server error:'
            + '\n  Error code: '+ operationstatus.errorCode
            + '.\n  Message: ' + operationstatus.errorMessage;
        this.loggingService.logError(errorMessage)
        throw new AppError(
          /* message= */ errorMessage,
          /* cause= */ undefined,
          /* httpErrorCode= */ undefined,
          /* serverErrorCode= */ operationstatus.errorCode);
      }
      return extractData(response);
    };
  }

  private httpPostWithBinaryRequestAndBinaryResponse(url: string, request: ArrayBuffer): Observable<ArrayBuffer> {
    const httpHeaders = new HttpHeaders();
    const observableHttpResponse = this.httpClient.post(url, request, {headers: httpHeaders, responseType: 'arraybuffer'})
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
    return observableHttpResponse;
  }

  private httpPostWithProtoRequestAndProtoResponse<T, R>(
      url: string,
      request:T,
      encodeRequest: Function<T, Uint8Array>,
      decodeResponse: Function<Uint8Array, R>): Observable<R> {
    const uint8Array = encodeRequest(request);
    const arrayBufferRequest = this.uint8ArrayToArrayBuffer(uint8Array);
    return this.httpPostWithBinaryRequestAndBinaryResponse(url, arrayBufferRequest)
    .pipe(
      map(
        arrayBufferResponse => {
          return decodeResponse(new Uint8Array(arrayBufferResponse));
      }));
  }

  private httpPostWithFile(url: string, fileFieldName: string, file: File): Observable<ArrayBuffer> {
    let httpHeaders = new HttpHeaders();
    // if (this.sessionService.getAuthToken()) {
    //   httpHeaders = httpHeaders.set("Auth-Token", this.sessionService.getAuthToken());
    // }
    const formData = new FormData();
    formData.append(fileFieldName, file);
    const observableHttpResponse = this.httpClient.post(url, formData, {headers: httpHeaders, responseType: 'arraybuffer'})
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
    return observableHttpResponse;
  }

  private uint8ArrayToArrayBuffer(uint8Array: Uint8Array): ArrayBuffer {
    const arrayBuffer = new ArrayBuffer(uint8Array.length);
    const auxbuffer = new Uint8Array(arrayBuffer);
    for (let i=0; i < uint8Array.length; i++) {
      auxbuffer[i] = uint8Array[i];
    }
    return arrayBuffer;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      this.loggingService.logError('Client error: ', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this.loggingService.logError(
        `HTTP error code: ${error.status}, body: `, error.error);
    }
    // Return an observable with a user-facing error message.
    // return throwError(() => new Error('Something bad happened; please try again later.'));
    return throwError(() => new AppError(/* message= */ 'HTTP error', /* cause= */ error, /* httpErrorCode= */ error.status));
  }

  private toAppError(error: any): AppError {
    if (error instanceof AppError) {
      return error;
    }
    return new AppError(/* message= */ 'Unknown error', /* cause= */ error);
  }
}
